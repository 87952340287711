import React, {useState, useEffect} from 'react';
import axios from 'axios'

export function useAxiosGet(url){

    const [request, setRequest] = useState({
        loading: false,
        data: null,
        error: false,
        itemsPerPage: 10,
    });

    useEffect(() => {
        setRequest({
            loading: true,
            data: null,
            error: false
        })
        
    axios.get(url)
    .then(response => {
        setRequest({
            loading: false,
            data: response.data,
            error: false,
            itemsPerPage: 10
        })
        
    })
        .catch(() =>{
            setRequest({
                loading: false,
                data: null,
                error: true
           
        })
    })
    }, [url]);


    return request
}